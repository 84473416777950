<div class="row method">
    <div class="col-lg-6">
        <!-- <div class="method-icon"><img src="assets/img/visa.png"></div> -->
        <p class="method-info text-muted">{{ tdc.type }}</p>
        <p class="method-info text-muted">{{ tdc.number}}</p>
        <p class="method-info text-muted">{{ tdc.status == 1 ? "Activa" : "Inactiva" }}</p>
    </div>
    <div class="col-lg-6">
    <div class="row">
    <div class="col-md-5 col-lg-4"></div>
    <div class="col-md-5 col-lg-4"></div>
    <div class="col-md-5 col-lg-4"></div>
    </div>
    <div class="row actions">
        <div class="col-md-5 col-lg-4"></div>
        <div *ngIf="tdc.status == 1" class="col-md-5 col-lg-4"><button (click)="changeStatus(2)" class="outline w-100 btn btn-primary btn-lg" type="submit">Desactivar</button></div>
        <div *ngIf="tdc.status == 0"  class="col-md-5 col-lg-4"><button (click)="changeStatus(1)" class="outline w-100 btn btn-primary btn-lg" type="submit">Activar</button></div>
        <!-- <div class="col-md-5 col-lg-4"><button class="outline w-100 btn btn-primary btn-lg" type="submit">Actualizar</button></div> -->
    </div>
    
</div>

<hr>