<!-- Navigation-->
<app-menu></app-menu>

<div id="page-top" [class]="class">

    <!-- Masthead-->
    <header [ngStyle]="{'background-image': getImagePrincipal() }" class="masthead" id="about">
        <div class="container">
                            <p class="sites-description">Preferencias</p>
        </div>
    </header>
        <main class="d-flex flex-nowrap ">

          <div class="side-bar-m bg-side d-flex flex-column flex-shrink-0 p-3 text-bg-dark" style="width: 280px;">
            <hr>
            <app-side-menu [option] = "4" ></app-side-menu>
            <hr>

          </div>


            <div class="b-example-divider b-example-vr"></div>
            <div class="container p-account">
                <div class="card shadow mb-4">
                    <div class="card-header py-3">
                            <h4 class="m-0">Cambiar contraseña</h4>
                    </div>
                    <div class="card-body">
                        <div class="row g-5">
                            <div class="col-md-5 col-lg-4 order-md-last">
                                <div class="card card-sm card-transition h-100 list-card" href="../documentation/bs-icons.html" data-aos="fade-up">
                                    <div class="card-body">
                                            <p class="card-title text-inherit">Recomendaciones</p>
                                        <ul class="check-list">
                                            <li>Una combinación de letras (a-z)</li>
                                            <li>Caracteres especiales</li>
                                            <li>Mayúsculas y minúsculas</li>
                                            <li>Números (0-9)</li>
                                            <li>Mínimo de 6 caracteres</li>
                                    </ul>

                                    </div>

                                </div>
                            </div>
                            <div class="col-md-7 col-lg-8">
                                <form [formGroup]="form" >
                                    <div class="row g-3">
                                        <div style="margin-top: 10px;" class="col-12">
                                            <label for="Name" class="form-label">Contraseña actual</label>
                                            <input autocomplete="actual_password" [formControl]="form.controls['actual_password']" type="password" class="form-control" >
											<small
												*ngIf="form.controls['actual_password'].hasError('required') && form.controls['actual_password'].touched"
												class="form-error-msg"> Contraseña actual es requerido </small>
                                        </div>
                                        <div style="margin-top: 10px;" class="col-12">
                                            <label for="Name" class="form-label">Nueva contraseña</label>
                                            <input autocomplete="new_password" [formControl]="form.controls['new_password']" type="password" class="form-control" >

                                            <small
                                            *ngIf="form.controls['new_password'].hasError('required') && form.controls['new_password'].touched"
                                            class="form-error-msg"> Nueva contraseña es requerido </small>

                                            <small
                                            *ngIf="form.controls['new_password'].hasError('minlength') && form.controls['new_password'].touched"
                                            class="form-error-msg"> Nueva contraseña debe tener mas de 6 caracteres </small>

                                        </div>
                                        <div style="margin-top: 10px;" class="col-12">
                                            <label for="Name" class="form-label">Repetir nueva contraseña actual</label>
                                            <input autocomplete="newPasswordConfirm" [formControl]="form.controls['newPasswordConfirm']" type="password" class="form-control" >

                                            <small
                                            *ngIf="form.controls['newPasswordConfirm'].hasError('required') && form.controls['newPasswordConfirm'].touched"
                                            class="form-error-msg"> Campo requerido </small>
                                            <small *ngIf="form.controls['newPasswordConfirm'].hasError('equalTo') && form.controls['newPasswordConfirm'].touched " class="form-error-msg">Contraseñas no coinciden</small>

                                        </div>
                                    </div>

                                </form>

                                    <div style="margin-top: 30px; " class="">
                                            <button (click)="send()" class="btn btn-primary btn-user btn-block">Guardar</button>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>



        </div>
        </main>

</div>
