
<div id="page-top" [class]="class">

    <!-- Masthead-->
    <header [ngStyle]="{'background-image': getImagePrincipal() }"  class="masthead full" id="about">
        <div class="container">
            <div class="masthead-heading">Inicia sesión</div>
                        <main class="form-signin">
                            <form  [formGroup]="form" (ngSubmit)="signin()" >
                                <div style="margin-top: 10px;" class="form-floating">
                                    <input [formControl]="form.controls['username']"  type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                                    <label for="floatingInput">Correo</label>
                                    <small 
                                    *ngIf="form.controls['username'].hasError('required') && form.controls['username'].touched" 
                                    class="form-error-msg"> Correo es requerido </small>
                                </div>
                                <div style="margin-top: 20px;" class="form-floating">
                                    <input [formControl]="form.controls['password']" type="password" class="form-control" id="floatingPassword" placeholder="Password">
                                    <label for="floatingPassword">Contraseña</label>
                                    <small 
                                    *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
                                    class="form-error-msg"> Contraseña es requerido </small>
                                </div>
                                <button class="btn btn-primary btn-xl " >Login</button>

                                <div style="margin-top: 20px;" >
                                    <a (click)="reset()" style="cursor: pointer;"> Olvidé mi contraseña</a>
                                </div>
          
                                <div style="margin-top: 20px;" >
                                    <a (click)="close()" style="cursor: pointer;"> Volver</a>
                                </div>
                                
                            </form>
                        </main>
        </div>
    </header>

</div>
