
<div id="page-top" [class]="class">

    <!-- Masthead-->
    <header [ngStyle]="{'background-image': getImagePrincipal() }"  class="masthead full" id="about">
        <div class="container">
            <div class="masthead-heading">Recupera tu Contraseña</div>

            <p class="sites-description">Te enviaremos un correo con tu nueva contraseña</p>
                        <main class="form-signin">
                            <form  [formGroup]="form" (ngSubmit)="reset()" >
                                <div style="margin-top: 10px;" class="form-floating">
                                    <input [formControl]="form.controls['email']"  type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                                    <label for="floatingInput">Correo</label>
                                    <small 
                                    *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                                    class="form-error-msg"> Correo es requerido </small>
                                </div>
                                <div style="margin-top: 20px;" class="checkbox mb-3">
                                    <a  (click)="onRegisterClick()" style="margin-top: 30px; cursor: pointer;"> Crear cuenta</a>
                                </div>
                                <button class="btn btn-primary btn-xl " >Recuperar</button>
                            </form>
                        </main>
        </div>
    </header>
        
</div>
