import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  @Output() manageStatus = new EventEmitter<{status : Number, id : Number}>();

  @Input() direction : {id:Number, address: String; status: Number; phone: String; province : Number; corregimiento : Number } | any  = null;

  @Output() editDirection = new EventEmitter<{id:Number, address: String; status: Number; phone: String; province : Number; corregimiento : Number }>();

  

  constructor() { }

  ngOnInit(): void {
  }

  changeStatus(newStatus : Number){
    this.manageStatus.emit({ status : newStatus, id: this.direction.id })
  }

  sendToEdit(item : any){
    this.editDirection.emit(item);
  }

}
