import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  provinces: any[]= [] ;
  form: any ;
  corregimientos: any[]= [] ;

  constructor(private auth : AuthService,private webService : WebService, private toast : ToastService, private spinner : SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateComponent>) { 
      this.spinner.open();  
      this.form = new FormGroup({
        //lastname: new FormControl('', Validators.required),
        //name: new FormControl('', Validators.required),
        //phone: new FormControl('', Validators.required),
        adress: new FormControl('', Validators.required),
        province : new FormControl('', Validators.required),
        corregimiento : new FormControl('', Validators.required)
      });

      this.webService.get(this.webService.HOST + "/province").subscribe( provinces =>{
        this.spinner.close();
        this.provinces = provinces.result;
      }, err =>{
        this.spinner.close()
        this.toast.showError(err);
      });

    }

  ngOnInit(): void {
    
  }

  onProvinceChange(){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == Number(this.form.value.province) )].corregimientos;
    this.corregimientos.sort((a,b) =>  (a.name > b.name ? 1 : -1));

  }


  create(){

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario")
    }

    this.spinner.open();
    this.form.value.customer = { id: this.auth.getCustomer() } ;
    this.form.value.status = 1;
    this.form.value.province = {id: Number(this.form.value.province) };
    this.form.value.corregimiento = {id: Number(this.form.value.corregimiento) }; // ESTO ESTA FIJO
    
    this.webService.post(this.form.value, this.webService.HOST + "/adress").subscribe(res =>{
        this.spinner.close();
        this.toast.showSuccess("Creada correctamente");
        this.close();
    }, err=>{
      this.spinner.close();
      this.toast.showError(err);
    })
    
  }

  close(){
    this.dialogRef.close();
  }


}
