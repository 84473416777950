import { Component, OnInit,Inject } from '@angular/core';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import {AuthService} from '../../../services/auth/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.css']
})
export class AddressPopupComponent implements OnInit {

  addresses : any = [];

  displayedColumns: string[] = ['name' ,'adress','actions'];
  dataSource: any;
  empty = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddressPopupComponent>,private auth : AuthService,private webService : WebService, private toast : ToastService, private spinner : SpinnerService) { }

  ngOnInit(): void {
    // LISTAMOS LAS DIRECCIONES DEL USUARIO
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/adress/customer/"+ this.auth.getCustomer()).subscribe(response =>{
      this.spinner.close();
      // CAMBIAMOS EL ESTARTUS A UNO MANEJABLE POR EL USUARIO
      if(response.result.length == 0){
        this.empty = true;
        return this.toast.showInfo("No se encontraron direcciones registradas");
      }

      for(let r of response.result){
        if( r.status == 1){ // SOLO SI LA DIRECCION SE ENCUENTRA ACTIVA
          this.addresses.push(r);
        }
      }

      this.dataSource = new MatTableDataSource<any>(this.addresses);
      

      }, err =>{
        this.toast.showError(err);
        this.spinner.close();
      });
  }

  select(row : any) {
    this.dialogRef.close(row);
  }

}
