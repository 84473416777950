<div *ngIf="empty">
    <h3>No posees direcciones asociadas, agrega una nueva dirección</h3>
</div>

<mat-table *ngIf="!empty" [dataSource]="dataSource"  >

<ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef > Nombre </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="name"> {{ row.name != null ? row.name : row.customer.name_customer }} </mat-cell>
</ng-container>

<ng-container matColumnDef="adress">
    <mat-header-cell *matHeaderCellDef > Dirección </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="adress"> {{row.adress}} </mat-cell>
</ng-container>


<ng-container matColumnDef="actions">
  <mat-header-cell *matHeaderCellDef > Acciones </mat-header-cell>
  <mat-cell *matCellDef="let row" data-label="actions">  <button mat-raised-button class="btn yellow" (click)="select(row)" >Seleccionar</button> </mat-cell>
</ng-container>

<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
<mat-row *matRowDef="let row; columns: displayedColumns;" >
</mat-row>
</mat-table> 

<mat-dialog-actions align="center">
    <button mat-raised-button style="margin-right: 20px; width: 14%;"  type="button" (click)="dialogRef.close(false)" >Volver</button>
</mat-dialog-actions>
 