import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import {AuthService} from '../../services/auth/auth.service';
import { CreatePaymentComponent } from './create-payment/create-payment.component';
import { ImagesService } from 'src/app/services/image/images.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {

  tdc: any = [];

  class : any = "pal1  preferences";
  tarjetas : any = [];
  items: Array<any> = [];

  constructor(private imageService: ImagesService, private auth : AuthService,private webService : WebService, private toast : ToastService, private spinner : SpinnerService,private dialog: MatDialog) {
    if(localStorage.getItem("pal") !== undefined || localStorage.getItem("pal") !== null){
      this.class = localStorage.getItem("pal") + "  preferences";
    }
   }

  ngOnInit(): void {

    this.spinner.open();
    this.items = [];
    this.webService.get(this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params =>{
      if(params['result'] != null){
        this.tarjetas = params['result'];
        ///Por cada tarjeta

        for(let i in this.tarjetas){
          let tdc = this.tarjetas[i].creditCard;
          //Se verifica la integridad de la tdc chequeando si posee un token en HecBillingCustomer
          this.webService.get(this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params => {
            //Si la tarjeta esta correctamente referenciada
            if(params['result'] != null) {
              switch(tdc.expMonthCreditCard){
                case 1 : tdc.expMonth = "ENERO";
                break;
                case 2 : tdc.expMonth = "FEBRERO";
                break;
                case 3 : tdc.expMonth = "MARZO";
                break;
                case 4 : tdc.expMonth = "ABRIL";
                break;
                case 5 : tdc.expMonth = "MAYO";
                break;
                case 6 : tdc.expMonth = "JUNIO";
                break;
                case 7 : tdc.expMonth = "JULIO";
                break;
                case 8 : tdc.expMonth = "AGOSTO";
                break;
                case 9 : tdc.expMonth = "SEPTIEMBRE";
                break;
                case 10 : tdc.expMonth = "OCTUBRE";
                break;
                case 11: tdc.expMonth = "NOVIEMBRE";
                break;
                case 12 : tdc.expMonth = "DICIEMBRE";
                break;
              }

              //Se carga el nombre del proveedor TDC en la columna indicada
              tdc.expMonthCreditCard = this.tarjetas[i].providerCreditCard.description;
              //Se corta el numero enmascarado de la TDC de manera de mostrar solo los ultimos 8 digitos
              tdc.maskCreditCard = tdc.maskCreditCard.substr(tdc.maskCreditCard.length-8);
              //Se muestra el año de vencimiento de la tarjeta en el formato YYYY
              tdc.expYearCreditCard = "20" + tdc.expYearCreditCard;
              //Se busca el estatus de la TDC de manera de mostrarlo al cliente
              this.webService.get(this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + tdc.id).subscribe(parms => {
                this.spinner.open();
                //Se guarda la descripcion del estado en la columna correspondiente
                tdc.estatus = parms['result']['statusCreditCard'] ;
                //Se anade la TDC a la tabla correspondiente
                this.items.push(tdc);
                this.tdc.push({ id : tdc.id, type : tdc.expMonthCreditCard, number : tdc.maskCreditCard, status : tdc.estatus})
               // this.dataSource = new MatTableDataSource<any>(this.items.slice(0,11));
               // this.tableService.pager.totalEntities = this.items.length ;
                this.spinner.close()

              }, (err) => {this.toast.showError(err);
                this.spinner.close(); });
            }




          }, (err) => {
            this.toast.showError(err);
            this.spinner.close();
           } );
        }


      }else{
        console.log("NO TDC");
        this.spinner.close();
      }


    }, err=>{
      this.toast.showError(err);
      this.spinner.close();
    });

  }

  add(){

    let dialogRef: MatDialogRef<any> = this.dialog.open(CreatePaymentComponent, {
      width: '80%',
      disableClose: true,
      data: { }
    })
      dialogRef.afterClosed()
      .subscribe(res => {
          window.location.reload();
      })

  }

  changeStatus(payment: any){

    this.spinner.open();

    this.webService.get(this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + payment.id).subscribe(tdc =>{

      let json = {token : tdc.result.tokenCreditCard }

      this.webService.post(json,this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard/' + tdc.result.id + '/delete').subscribe(response =>{
          this.spinner.close();
          this.toast.showSuccess("Procesada correctamente");
          window.location.reload();
        }, (err) => {
          this.spinner.close();
          this.toast.showError("No se pudo cambiar el estado de la tarjeta, verifique que no se encuentre asociada a un plan activo");
        })

      }, err =>{
        console.log(err);
       this.toast.showError(err);
      }) ;
  }

  getImagePrincipal(){
    return this.imageService.getImagePalete();
  }

}
