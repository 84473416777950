import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CreateComponent } from './create/create.component';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import {AuthService} from '../../services/auth/auth.service';
import { UpdateComponent } from './update/update.component';
import { ImagesService } from 'src/app/services/image/images.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})
export class AddressesComponent implements OnInit {

  addresses : any = [];

  class : any = "pal1  preferences";

  constructor(private imageService: ImagesService, private auth : AuthService,private webService : WebService, private toast : ToastService, private spinner : SpinnerService,private dialog: MatDialog) {
    if(localStorage.getItem("pal") !== undefined || localStorage.getItem("pal") !== null){
      this.class = localStorage.getItem("pal") + "  preferences";
    }
    // LISTAMOS LAS DIRECCIONES DEL USUARIO
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/adress/customer/"+ this.auth.getCustomer()).subscribe(response =>{
      this.spinner.close();
      // CAMBIAMOS EL ESTARTUS A UNO MANEJABLE POR EL USUARIO
      if(response.result.length == 0){
        return this.toast.showInfo("No se encontraron direcciones registradas");
      }

      for(let item of response.result){
        this.addresses.push({ id : item.id, address :  item.adress ,status :  item.status  , phone :  item.phone, province : item.province.id, corregimiento : item.corregimiento.id })
      }
      // this.dataSource = new MatTableDataSource<any>(response.result);
      // this.tableService.pager = response.pager;
      // this.tableService.selection.clear();


      }, err =>{
        this.toast.showError(err);
        this.spinner.close();
      });
   }

  ngOnInit(): void {

  }

  add(){

    let dialogRef: MatDialogRef<any> = this.dialog.open(CreateComponent, {
      width: '80%',
      disableClose: true,
      data: { }
    })
      dialogRef.afterClosed()
      .subscribe(res => {
          window.location.reload();
      })

  }

  changeStatus(direction: any){
      this.webService.put(direction, this.webService.HOST + "/adress/" + direction.id ).subscribe(res =>{
        console.log(res);
        this.toast.showSuccess("Actualizada correctamente");
        window.location.reload();
      }, err=>{
        this.spinner.close();
        this.toast.showError(err);
      });
  }


  update(item : any){
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateComponent, {
      width: '80%',
      disableClose: true,
      data: { item : item }
    })
      dialogRef.afterClosed()
      .subscribe(res => {
        window.location.reload();
      })
  }

  getImagePrincipal(){
    return this.imageService.getImagePalete();
  }

}
