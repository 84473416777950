import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  suscription = false;
  payment = false;
  address = false;
  change = false;

  @Input() option  : number = 0 ;

  constructor() { }

  ngOnInit(): void {

    switch(this.option){
      case 1 : this.suscription = true;
      break;
      case 2 : this.payment = true;
      break;
      case 3 : this.address = true;
      break;
      case 4 : this.change = true;
      break;
      default : console.log("NO HAY OPCION CONFIGURADO");
    }


  }

}
