<!-- Navigation-->
<app-menu [navigation]="aloudNavigation"></app-menu>



<div id="page-top" [class]="class">

    <!-- Masthead-->
    <header [ngStyle]="{'background-image': getImagePrincipal() }" class="masthead" id="about">
        <div class="container">
            <div *ngIf="isSuscriptionActive" class="masthead-heading"> {{ title }} </div>
            <div *ngIf="!isSuscriptionActive" class="masthead-heading">PORTAL EN MANTENIMIENTO</div>
                        <p class="sites-description"> {{ description }} </p>
            <a *ngIf="aloudNavigation" class="btn btn-primary btn-xl " href="#portfolio">Ver Planes</a>
        </div>
    </header>
        
        <!-- Planes Grid-->
    <section *ngIf="isSuscriptionActive" class="page-section prices" id="portfolio">
        <div class="container">
            <div class="text-center">
                                <h3 class="section-subheading text-muted">Precios</h3>
                                <h2 class="section-heading"> {{ price_title }} </h2>
            </div>
            <!-- MOSTRAMOS LOS PLANES QUE TIENE ASOCIADO EL SERVICIO DEL ONBOARDING CONFIG -->
            <app-plan [navigation]="aloudNavigation" ></app-plan>

        </div>
    </section>
    <!-- Banner-->
    <section *ngIf="isSuscriptionActive" class="page-section banner-promotion" id="banner">
        <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1>{{ banner_title }}</h1>
                                <p class="sites-description"> {{ banner_description }} </p>
                            </div>
                            
                            <div class="col-lg-6 img-prom">
                                <div style="width: 100%; height: 400px;" class="product-img"><img style="left: 0; right: 0; width: 100%; margin: auto;" [src]="getImagePromotional()"></div>
                            </div>
                        </div>
        </div>
    </section>

</div>
