<!-- Navigation-->
<app-menu></app-menu>

<div id="page-top" [class]="class">

   <!-- Masthead-->
   <header [ngStyle]="{'background-image': getImagePrincipal() }" class="masthead" id="about">
       <div class="container">
                           <p class="sites-description">Preferencias</p>
       </div>
   </header>
       <main class="d-flex flex-nowrap ">


         <div class="side-bar-m bg-side d-flex flex-column flex-shrink-0 p-3 text-bg-dark" style="width: 280px;">
           <hr>
           <app-side-menu [option] = "3" ></app-side-menu>
           <hr>

         </div>


           <div class="b-example-divider b-example-vr"></div>
           <div class="container p-account">
               <div class="card shadow mb-4">
                   <div class="card-header py-3">
                           <h4 class="m-0">Direcciones</h4>
                           <a (click) = "add()" style="float: right; cursor: pointer;"> Agregar nueva <span>+</span></a>
                   </div>
                   <!-- app-suscription -->
                   <div class="card-body">

                        <app-address (manageStatus)="changeStatus($event)" (editDirection)="update($event)" *ngFor=" let add of addresses " [direction] = "add" > </app-address>

                   </div>
                </div>
            </div>
       </main>

</div>
