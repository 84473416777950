import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagesService } from 'src/app/services/image/images.service';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';

@Component({
  selector: 'app-singup',
  templateUrl: './singup.component.html',
  styleUrls: ['./singup.component.css']
})
export class SingupComponent implements OnInit {

  form: any ;
  errorNumber = true;
  numberRequired = false;
  class : any = "pal1";

  config : any;
  partner : any;
  service: any;

  constructor(private router : Router,private activeRoute : ActivatedRoute,private imageService: ImagesService, private webService : WebService, private toast : ToastService, private spinner : SpinnerService) {
    this.form = new FormGroup({
      name_customer: new FormControl('', Validators.required),
      lastName_customer: new FormControl('', Validators.required),
      identifier_customer: new FormControl('', Validators.required),
      email_customer: new FormControl('', [Validators.required,Validators.email]),
      phoneNumber_customer:new FormControl('',Validators.required)
    });

    if(localStorage.getItem("pal") !== undefined || localStorage.getItem("pal") !== null){
      this.class = localStorage.getItem("pal");
    }

   }

  ngOnInit(): void {

    this.spinner.open();

    this.activeRoute.params.subscribe( params =>{

      let param = params.id != undefined ? params.id : -1;

        // dev-onboarding-contemporary.billcentric.com
          // window.location.host
     // this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + "test-pedro-local.billcentric.com" + "/"  + param).subscribe( response =>{
          //this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + "dev-onboarding-contemporary.billcentric.com" + "/"  + param).subscribe( response =>{
            this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + window.location.host + "/"  + param ).subscribe( response =>{
            this.spinner.close();

            if(param === -1 && (response.result.service.current_onboarding_step == 6 ||  response.result.service.current_onboarding_step == 7) ){
              this.partner = response.result.partner.id;
              this.service = response.result.service.id;
              this.config = response.result.id;
  
            }else{
              return this.toast.showError("Por favor, culmina el pago de tu portal para que se pueda desplegar correctamente...");
            }
            
           
          }, err =>{
            this.spinner.close();
            this.toast.showError(err);
          });



    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }


   //Permite solo numeros en el numero de la TDC
   onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.form.controls["phoneNumber_customer"].setValue(obj);
  }

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  signup(){

    if(!this.form.valid){
      return this.toast.showError("Por favor, complete el formulario");
    }

    this.form.value.config = this.config;
    this.form.value.code_customer = this.form.value.email_customer;
    this.form.value.status_customer = 1;
    this.form.value.service = this.service;
    this.form.value.partner = { id : Number(this.partner)};

    this.spinner.open();

    this.webService.post(this.form.value, this.webService.HOST + "/onboarding/user").subscribe(response =>{
      this.toast.showSuccess("Cuenta creada correctamente, se ha enviado un correo con sus credenciales, por favor inicie sesión");
      this.spinner.close();
      this.router.navigate(["/login"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});

    }, err =>{
      this.toast.showError(err);
      console.log(err);
      this.spinner.close();
    })

    console.log(this.form.value);
  }

  getImagePrincipal(){
    return this.imageService.getImagePalete();
  }

}
