import { Component, OnInit,Inject } from '@angular/core';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import {AuthService} from '../../../services/auth/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-payments-popup',
  templateUrl: './payments-popup.component.html',
  styleUrls: ['./payments-popup.component.css']
})
export class PaymentsPopupComponent implements OnInit {

  displayedColumns: string[] = ['nameHolderCreditCard' ,'expMonthCreditCard','actions'];
  dataSource: any;
  empty = false;

  tdc: any = [];
  tarjetas : any = [];
  items: Array<any> = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<PaymentsPopupComponent>,private auth : AuthService,private webService : WebService, private toast : ToastService, private spinner : SpinnerService) { }

  ngOnInit(): void {

    this.spinner.open();
    this.items = [];
    this.webService.get(this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params =>{
      if(params['result'] != null){
        this.tarjetas = params['result'];
        ///Por cada tarjeta
        for(let i in this.tarjetas){
          let tdc = this.tarjetas[i].creditCard;
          //Se verifica la integridad de la tdc chequeando si posee un token en HecBillingCustomer
          this.webService.get(this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params => {
            //Si la tarjeta esta correctamente referenciada
            if(params['result'] != null) {
              switch(tdc.expMonthCreditCard){
                case 1 : tdc.expMonth = "ENERO";
                break;
                case 2 : tdc.expMonth = "FEBRERO";
                break;
                case 3 : tdc.expMonth = "MARZO";
                break;
                case 4 : tdc.expMonth = "ABRIL";
                break;
                case 5 : tdc.expMonth = "MAYO";
                break;
                case 6 : tdc.expMonth = "JUNIO";
                break;
                case 7 : tdc.expMonth = "JULIO";
                break;
                case 8 : tdc.expMonth = "AGOSTO";
                break;
                case 9 : tdc.expMonth = "SEPTIEMBRE";
                break;
                case 10 : tdc.expMonth = "OCTUBRE";
                break;
                case 11: tdc.expMonth = "NOVIEMBRE";
                break;
                case 12 : tdc.expMonth = "DICIEMBRE";
                break;
              }

              //Se carga el nombre del proveedor TDC en la columna indicada
              tdc.expMonthCreditCard = this.tarjetas[i].providerCreditCard.description;
              //Se corta el numero enmascarado de la TDC de manera de mostrar solo los ultimos 8 digitos
              tdc.maskCreditCard = tdc.maskCreditCard.substr(tdc.maskCreditCard.length-8);
              //Se muestra el año de vencimiento de la tarjeta en el formato YYYY
              tdc.expYearCreditCard = "20" + tdc.expYearCreditCard;
              //Se busca el estatus de la TDC de manera de mostrarlo al cliente
              this.webService.get(this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + tdc.id).subscribe(parms => {
                this.spinner.open();
                //Se guarda la descripcion del estado en la columna correspondiente
                tdc.estatus = parms['result']['statusCreditCard'] ;
                tdc.token = parms['result']['tokenCreditCard'];
                //Se anade la TDC a la tabla correspondiente
                this.items.push(tdc);
                this.dataSource = new MatTableDataSource<any>(this.items);
                this.spinner.close()

              }, (err) => {this.toast.showError(err);
                this.spinner.close(); });
            }

          }, (err) => {
            this.toast.showError(err);
            this.spinner.close();
           } );
        }

      }else{
        console.log("NO TDC");
        this.spinner.close();
      }

    }, err=>{
      this.toast.showError(err);
      this.spinner.close();
    });

  }

  select(row : any) {
    this.dialogRef.close(row);
  }

}
