<div class="row">
    <div class="col-lg-6">
        <p class="subdescrition"> {{ direction.address }} </p>
        <div class="portfolio-caption-heading">{{ direction.status == 1 ? "Activa" : "Inactiva" }}</div>
        <div class="portfolio-caption-subheading text-muted">{{ direction.phone == null ? "N/A" : direction.phone }}</div>
        <!-- <p class="text-muted">Próxima facturación es el {{ plan.next_bill_date }}.</p> -->
    </div>
    <div class="col-lg-6">
        <div class="row actions">
            <div class="col-md-5 col-lg-4"></div>
            <div *ngIf="direction.status == 1" class="col-md-5 col-lg-4"><button (click)="changeStatus(2)" class="outline w-100 btn btn-primary btn-lg">Desactivar</button></div>
            <div *ngIf="direction.status == 2" class="col-md-5 col-lg-4"><button (click)="changeStatus(1)" class="outline w-100 btn btn-primary btn-lg">Activar</button></div>
            <div class="col-md-5 col-lg-4"><button  (click)="sendToEdit(direction)" class="w-100 btn btn-primary btn-lg" >Actualizar</button></div>
        </div>
    </div>

    <hr>
</div>
