import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import { HttpParams} from '@angular/common/http';
import { ImagesService } from 'src/app/services/image/images.service';
import { LoginService } from '../../login/login.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})
export class LoginPopupComponent implements OnInit {

  form : any;

  class : any = "pal1";

  fromCheckout : Boolean = false;

  isLoginFromCheckout : Boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<LoginPopupComponent>,public loginService : LoginService,private activeRoute : ActivatedRoute, private imageService : ImagesService, private router : Router,private webService : WebService, private toast : ToastService, private spinner : SpinnerService) {
    
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required,Validators.email]),
      password: new FormControl('', Validators.required)
    });

    if(loginService.getFromCheckout() != null){
      this.form.controls["username"].setValue(loginService.getFromCheckout());
      this.isLoginFromCheckout = true;
      if(loginService.getPassFromCheckout() != null){
        this.form.controls["password"].setValue(loginService.getPassFromCheckout());
        this.signin();
      }
      loginService.removeFromCheckout();
    }

    if(localStorage.getItem("pal") !== undefined || localStorage.getItem("pal") !== null){
      this.class = localStorage.getItem("pal");
    }

    this.activeRoute.queryParams.subscribe( queryParams =>{
          if(queryParams.checkout){
            this.fromCheckout = true;
          }
    }, err =>{
      this.toast.showError(err);
    });


   }

  ngOnInit(): void {

  }


  signin(){

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario...");
    }

    this.spinner.open();

    const signinData = this.form.value;

    let params = new HttpParams() ;
   
		params = params.set('grant_type', 'password');
		params = params.set('username', signinData.username);
		params = params.set('password', signinData.password);
		params = params.set('client_id', 'web_site');
    params = params.set('partner_id', Number(localStorage.getItem("partner")));

    //SOLICITAMOS TOKEN
    this.webService.auth(params,this.webService.HOST + "/oauth/token").subscribe( res =>{
      
      this.spinner.close();
      localStorage.setItem('sesion',JSON.stringify(res));

      if(!this.isLoginFromCheckout){ // DECIDIMOS DONDE ENVIAR AL CLIENTE, EN CASO DE QUE VENGA DESDE EL CHECKOUT ENTONCES LO DEVOLVEMOS
        this.toast.showSuccess("Ha iniciado sesión correctamente");
        this.router.navigateByUrl("/suscriptions");
      }else{
        this.dialogRef.close(1);
      }
      

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }


  getImagePrincipal(){
    return this.imageService.getImagePalete();
  }

  close(){
    this.dialogRef.close(0);
  }

  reset(){

    if(this.form.value.username == '' || this.form.value.username == undefined){
      return this.toast.showError("Por favor ingrese un correo electronico...");
    }

    this.form.value.email = this.form.value.username;
    this.form.value.partner = localStorage.getItem("partner");
    this.form.value.service = localStorage.getItem("service");

    this.spinner.open();
    this.webService.post(this.form.value, this.webService.HOST + "/onboarding/user/resetPassword").subscribe(response =>{
        this.spinner.close();
        this.toast.showSuccess("Se enviara un nuevo password a su correo, por favor inicie sesión con sus credenciales nuevas.");
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }

}
