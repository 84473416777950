import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImagesService } from 'src/app/services/image/images.service';
import { ToastService } from 'src/app/services/notification/toast.service';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  logoImage : any;

  @Input() navigation = true;


  constructor(private router : Router, private toast : ToastService, public auth : AuthService, private imageService : ImagesService) {

  }

  ngOnInit(): void {
    this.imageService.logoImage.subscribe((url : string) =>{
      this.imageService.setLogoImage(url);
      this.logoImage = url;
    });
    this.logoImage = this.imageService.getLogoImage();
  }

  logout(){
    this.auth.closeSesion();
    this.toast.showSuccess("Ha salido de la sesion correctamente");
    this.router.navigateByUrl("/");
  }

}
