<!-- Navigation-->
<app-menu></app-menu>

<div id="page-top" [class]="class">

    <!-- Masthead-->
    <header [ngStyle]="{'background-image': getImagePrincipal() }"  class="masthead full" id="about">
        <div class="container">
            <div class="masthead-heading">Inicia sesión</div>
                        <main class="form-signin">
                            <form  [formGroup]="form" (ngSubmit)="signin()" >
                                <div style="margin-top: 10px;" class="form-floating">
                                    <input [formControl]="form.controls['username']"  type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                                    <label for="floatingInput">Correo</label>
                                    <small 
                                    *ngIf="form.controls['username'].hasError('required') && form.controls['username'].touched" 
                                    class="form-error-msg"> Correo es requerido </small>
                                </div>
                                <div style="margin-top: 20px;" class="form-floating">
                                    <input [formControl]="form.controls['password']" type="password" class="form-control" id="floatingPassword" placeholder="Password">
                                    <label for="floatingPassword">Contraseña</label>
                                    <small 
                                    *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
                                    class="form-error-msg"> Contraseña es requerido </small>
                                </div>
                                <!-- <div class="checkbox mb-3">
                                    <label style="margin-top: 20px;"><input type="checkbox" value="remember-me"> Remember me</label>
                                </div>  -->
                                <div style="margin-top: 20px;" class="checkbox mb-3">
                                    <a  (click)="onRegisterClick()" style="margin-top: 30px; cursor: pointer;"> Crear cuenta</a>
                                </div>
                                <div class="checkbox mb-3">
                                    <a (click)="onforgotClick()" style="margin-top: 20px; cursor: pointer;"> Olvidé mi contraseña</a>
                                </div>
                                <button class="btn btn-primary btn-xl " >Login</button>
                            </form>
                        </main>
        </div>
    </header>

</div>
