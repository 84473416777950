
<div class="row no-padding-cols">
    <!-- featured-plan -->
    <div *ngFor="let plan of plans" class="col-lg-4 col-sm-6 mb-4">
        <div  class="portfolio-item box-plan">
            <a class="portfolio-link">
                <div class="portfolio-hover">
                    <div class="portfolio-hover-content"></div>
                </div>
                <img class="img-fluid" [src]="plan.getImage()" alt="..." />
            </a>
            <div class="portfolio-caption">
                <div style="text-align: center;">
                    <p class="subdescrition">{{ plan.getName() }}</p>
                    <div  class="portfolio-caption-heading">${{plan.getPrice()}}</div>
                    <div class="portfolio-caption-subheading text-muted">{{ plan.getDescription() }}</div>
                                              <!--   <ul class="benefits">
                                                    <li>Beneficio 1</li>
                                                    <li>Beneficio 2</li>
                                                    <li>Beneficio 3</li>
                                                    
                                                </ul> -->
                    <a *ngIf="navigation" class="btn btn-primary " (click)="onSelectPlan(plan)" routerLink = "/checkout">Lo quiero</a>
                </div>
               
            </div>
        
        </div>
    </div>

</div>

