
<ngx-spinner  bdColor="rgba(51,51,51,0.95)"
size="medium"
color="#fff"  type="ball-scale-multiple"> <p style="font-size: 20px; color: white">Espere...</p> </ngx-spinner>

<router-outlet>


</router-outlet>

<app-footer></app-footer>

