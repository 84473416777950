import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { ImagesService } from 'src/app/services/image/images.service';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  
  form: any ;
  class : any = "pal1  preferences";

  constructor(private imageService : ImagesService, private webService : WebService, private toast : ToastService, private spinner : SpinnerService) {
    if(localStorage.getItem("pal") !== undefined || localStorage.getItem("pal") !== null){
      this.class = localStorage.getItem("pal") + "  preferences";
    }

    const newPassword = new FormControl('', [Validators.required,Validators.minLength(6)]);
    const newPasswordConfirm = new FormControl('', [Validators.required, CustomValidators.equalTo(newPassword)]);

    this.form = new FormGroup({
      actual_password: new FormControl('', [Validators.required]),
      new_password: newPassword,
      newPasswordConfirm: newPasswordConfirm
    });

   }

  ngOnInit(): void {
   
  }

  send(){
    this.form.value.user = JSON.parse(localStorage.getItem('sesion')!).user_id;
    this.form.value.partner = JSON.parse(localStorage.getItem('sesion')!).partner;

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario correctamente...");
    }

   
    this.spinner.open();

    this.webService.put(this.form.value, this.webService.HOST + "/onboarding/changePassword").subscribe( response =>{
      this.spinner.close();
      this.form.reset();
      this.toast.showSuccess("Contraseña cambiada con exito");
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
      console.log(err)
    })

  }

  getImagePrincipal(){
    return this.imageService.getImagePalete();
  }

}
