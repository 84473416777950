import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import { ImagesService } from 'src/app/services/image/images.service';


@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  class : any = "pal1";

  form : any;

  constructor(private activateRoute : ActivatedRoute,private activeRoute : ActivatedRoute,private imageService : ImagesService, private router : Router,private webService : WebService, private toast : ToastService, private spinner : SpinnerService) {
    
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email]),
      partner : new FormControl('', Validators.required),
      service : new FormControl('', Validators.required)
    });

    if(localStorage.getItem("pal") !== undefined || localStorage.getItem("pal") !== null){
      this.class = localStorage.getItem("pal");
    }

    this.activateRoute.params.subscribe( params =>{
      let param = params.id != undefined ? params.id : -1;
      this.spinner.open();
      //this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + "test-pedro-local.billcentric.com" + "/"  + param).subscribe( response =>{
     this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + window.location.host + "/"  + param ).subscribe( response =>{
        this.spinner.close();

        if(param === -1 && (response.result.service.current_onboarding_step == 6 ||  response.result.service.current_onboarding_step == 7) ){
          this.form.controls["partner"].setValue(response.result.partner.id);
          this.form.controls["service"].setValue(response.result.service.id);
        }else{
          return this.toast.showError("Por favor, culmina el pago de tu portal para que se pueda desplegar correctamente...");
        }
        
       
      }, err =>{
        this.spinner.close();
        this.toast.showError(err);
      });
      
    });

   }

  ngOnInit(): void {
  }

  onRegisterClick(){
    this.router.navigate(["/singup"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
  }

  onforgotClick(){
    this.router.navigate(["/forgot"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});  
  }

  reset(){

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario...");
    }
    this.spinner.open();
    this.form.partner = localStorage.getItem("partner");
    this.webService.post(this.form.value, this.webService.HOST + "/onboarding/user/resetPassword").subscribe(response =>{
        this.spinner.close();
        this.toast.showSuccess("Se enviara un nuevo password a su correo, por favor inicie sesión con sus credenciales nuevas.");
        this.router.navigate(["/login"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }

  getImagePrincipal(){
    return this.imageService.getImagePalete();
  }

}
