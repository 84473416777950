 <!-- app-suscription -->
 <div class="card-body">
    <div *ngFor=" let sus of suscriptions" class="row">
        <div class="col-lg-6">
            <p class="subdescrition"> {{ sus.plan.name_plan }} </p>
            <div class="portfolio-caption-heading">${{ sus.plan.feeCost_plan }} - {{ sus.status_customerPlan }}</div>
            <div class="portfolio-caption-subheading text-muted">{{ sus.plan.description }}</div>
            <p class="text-muted">Próxima facturación es el {{ sus.next_bill_date }}.</p>
        </div>
        <div class="col-lg-6">
            <div class="row actions">
                <div class="col-md-5 col-lg-4"></div>
                <div class="col-md-5 col-lg-4"><button class="w-100 btn btn-primary btn-lg" (click)="onUpdate(sus)" type="submit">Actualizar</button></div>
            </div>
        </div>

        <hr>
    </div>
 </div>
