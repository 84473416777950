import { Injectable } from "@angular/core";
import {AuthService} from '../services/auth/auth.service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { ToastService } from 'src/app/services/notification/toast.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private toast : ToastService,private router: Router, private auth : AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.auth.isLoggedIn()) { // LO DEJAMOS AVANZAR SI ESTA LOGEADO
      return true;
    } else { // CASO CONTRARIO LO MANDAMOS A INICIAR SESION NUEVAMENTE
      
      this.toast.showInfo("Por favor inicie sesion...");
        this.router.navigate(["/login"], {
          queryParams: {
            return: state.url
          }
        });

      return false;
    }

  }
}
